import moment from 'moment'

export const getParticipantForThread = (thread, user, stores) => {
  const userParticipant = thread.users.find((u) => u.id === user.id)

  if (userParticipant) {
    return {
      ...userParticipant,
      type: 'user',
    }
  }

  let storeParticipant = null

  thread.stores.forEach((store) => {
    if (stores.find((s) => s.id === store.id)) {
      storeParticipant = store
    }
  })

  return {
    ...storeParticipant,
    type: 'store',
  }
}

export const getOtherParticipants = (thread, user, stores) => {
  const authParticipant = getParticipantForThread(thread, user, stores)

  const participants = [
    ...thread.users.map((user) => ({ ...user, type: 'user' })),
    ...thread.stores.map((store) => ({ ...store, type: 'store' })),
  ]

  return participants.filter(
    (participant) =>
      participant.type !== authParticipant.type || participant.id !== authParticipant.id
  )
}

export const formatThreadForHeaderLink = (thread, user, stores) => {
  const authParticipant = getParticipantForThread(thread, user, stores)

  return {
    ...thread,
    otherParticipants: getOtherParticipants(thread, user, stores),
    participatingAs: authParticipant,
    preview: thread.last_message.preview,
    lastMessageCreatedAt: moment(thread.last_message.created_at).fromNow(),
  }
}

export const sortThreadsByLastMessage = (threads) => {
  return [...threads].sort((a, b) => {
    return moment(b.last_message.created_at).diff(moment(a.last_message.created_at))
  })
}

export const getAccountThreads = (threads, user, stores) => {
  return [...threads]
    .filter(
      (thread) => getParticipantForThread(thread, user, stores).pivot.participant_type === 'user'
    )
    .map((thread) => formatThreadForHeaderLink(thread, user, stores))
}

export const getStoreThreads = (threads, user, stores) => {
  return [...threads]
    .filter(
      (thread) => getParticipantForThread(thread, user, stores).pivot.participant_type === 'store'
    )
    .map((thread) => formatThreadForHeaderLink(thread, user, stores))
}

export const groupStoreThreads = (threads) => {
  return [...threads].reduce((carry, thread) => {
    const store = thread.participatingAs
    if (!carry[store.id]) {
      carry[store.id] = {
        store,
        threads: [],
      }
    }

    carry[store.id].threads.push(thread)
    return carry
  }, {})
}

export const addThreadAndIncrementCount = (thread, participant, storeState) => {
  const existingThread = storeState.threads.find((t) => t.id === thread.id)

  if (existingThread) {
    // Update existing thread
    storeState.threads = storeState.threads.map((t) => {
      if (t.id === thread.id) {
        return thread
      }
      return t
    })
  } else {
    // add and increment count for a new thread
    storeState.threads = [...storeState.threads, thread]

    storeState.lastParticipantType = participant.pivot.participant_type

    if (participant.pivot.participant_type === 'user') {
      storeState.accountMessagesTotal += 1
    } else {
      if (storeState.storeMessageCounters[participant.id]) {
        storeState.storeMessageCounters[participant.id] += 1
      } else {
        storeState.storeMessageCounters[participant.id] = 1
      }
    }
  }
}

export const removeThreadAndDecrementCount = (thread, participant, storeState) => {
  // find exising thread
  const existingThread = storeState.threads.find((t) => t.id === thread.id)

  if (existingThread) {
    storeState.threads = storeState.threads.filter((t) => t.id !== thread.id)

    // decrement counter based on type
    if (participant.pivot.participant_type === 'user') {
      storeState.accountMessagesTotal -= 1
    } else {
      if (storeState.storeMessageCounters[participant.id]) {
        storeState.storeMessageCounters[participant.id] -= 1
      }
    }
  }
}

export const addOrRemoveThread = (thread, authParticipant, storeState) => {
  const participantLastReadAt = moment(authParticipant.pivot.last_read_at).toDate()
  const lastMessageCreatedAt = moment(thread.last_message.created_at).toDate()

  if (participantLastReadAt < lastMessageCreatedAt) {
    addThreadAndIncrementCount(thread, authParticipant, storeState)
  } else {
    removeThreadAndDecrementCount(thread, authParticipant, storeState)
  }
}
