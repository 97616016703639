import { getAlgoliaResults } from '@algolia/autocomplete-js'

export default (searchClient, indexName) => {
  return {
    sourceId: 'contents',
    getItemInputValue: ({ item }) => item.query,
    getItems({ query }) {
      // return empty result if no query is provided
      if (query.length === 0) {
        return []
      }

      return getAlgoliaResults({
        searchClient,
        queries: [
          {
            indexName: indexName,
            query,
            params: {
              hitsPerPage: 2,
              attributesToSnippet: ['name:20', 'category:20'],
              snippetEllipsisText: '…',
              clickAnalytics: true,
            },
          },
        ],
      })
    },
    onSelect({ item }) {
      window.location.href = item.url
    },
    templates: {
      header({ items, createElement, Fragment }) {
        if (items.length === 0) {
          return null
        }

        return createElement(
          Fragment,
          null,
          createElement('span', { className: 'aa-SourceHeaderTitle' }, 'Article Suggestions'),
          createElement('div', { className: 'aa-SourceHeaderLine' })
        )
      },
      item({ item, components, createElement }) {
        return createElement(
          'a',
          { className: 'aa-ItemLink aa-ArticleItem', href: item.url },
          createElement(
            'div',
            { className: 'aa-ItemContent' },
            createElement(
              'div',
              {
                className: 'aa-ItemPicture',
              },
              createElement('img', {
                src: item.image,
                alt: item.name,
              })
            ),
            createElement(
              'div',
              { className: 'aa-ItemContentBody' },
              createElement(
                'div',
                { className: 'aa-ItemContentTitle' },
                components.Highlight({ hit: item, attribute: 'name' })
              ),
              createElement(
                'div',
                { className: 'aa-ItemContentSubtitle' },
                components.Highlight({ hit: item, attribute: 'category' })
              )
            )
          )
        )
      },
    },
  }
}
