import { computed, isProxy, reactive, toRaw } from 'vue'
import {
  addOrRemoveThread,
  getAccountThreads,
  getParticipantForThread,
  getStoreThreads,
  groupStoreThreads,
  sortThreadsByLastMessage
} from "@/Utils/messagesDropdownHelpers.js"

export const state = reactive({
  // Auth
  user: null,
  stores: [],

  // Threads
  accountMessagesTotal: 0,
  storeMessageCounters: {},
  lastParticipantType: null,
  threads: [],
})

export const stores = computed(() => state.stores)
export const sortedThreads = computed(() => sortThreadsByLastMessage(state.threads))
export const accountThreads = computed(() => getAccountThreads(sortedThreads.value, state.user, state.stores))
export const storeThreads = computed(() => getStoreThreads(sortedThreads.value, state.user, state.stores))
export const groupedStoreThreads = computed(() => groupStoreThreads(storeThreads.value))
export const lastParticipantType = computed(() => state.lastParticipantType)
export const accountMessagesTotal = computed(() => state.accountMessagesTotal)
export const storeMessagesTotal = computed(() => Object.values(state.storeMessageCounters).reduce((total, n) => total + n, 0))
export const messagesTotal = computed(() => accountMessagesTotal.value + storeMessagesTotal.value)
export const storeMessageCounters = computed(() => state.storeMessageCounters)

export const setUser = (user) => {
  state.user = user
}

export const setStores = (stores) => {
  state.stores = stores
}

export const setThreads = (threads) => {
  if (threads) {
    state.threads = [...threads]
  }
}

export const setCounters = (counters) => {
  if (counters) {
    state.accountMessagesTotal = counters.account
    state.storeMessageCounters = counters.stores
  }
}

export const toggleThread = (thread) => {
  addOrRemoveThread(thread, getParticipantForThread(thread, state.user, state.stores), state)
}

export const syncState = (page) => {
  // see onMounted hook in Header.vue (this initialises all state for the header)
  if (page.props.header.counters.messages.threads !== undefined) {
    setThreads(isProxy(page.props.header.counters.messages.threads) ? toRaw(page.props.header.counters.messages.threads) : page.props.header.counters.messages.threads)
    setCounters(page.props.header.counters.messages.counters)
  }

  if (page.props.auth.user !== undefined) {
    setUser(page.props.auth.user)
  }

  if (page.props.auth.stores !== undefined) {
    setStores(page.props.auth.stores)
  }
}

export const alpineStore = {
  // Auth
  user: null,
  stores: [],

  // Messages
  accountMessagesTotal: 0,
  storeMessageCounters: {},
  lastParticipantType: null,
  threads: [],

  // Getters
  get sortedThreads() {
    return sortThreadsByLastMessage(this.threads)
  },
  get accountThreads() {
    return getAccountThreads(this.sortedThreads, this.user, this.stores)
  },
  get storeThreads() {
    return getStoreThreads(this.sortedThreads, this.user, this.stores)
  },
  get groupedStoreThreads() {
    return groupStoreThreads(this.storeThreads)
  },
  get storeMessagesTotal() {
    return Object.values(this.storeMessageCounters).reduce((total, n) => total + n, 0)
  },
  get messagesTotal() {
    return this.accountMessagesTotal + this.storeMessagesTotal
  },

  // Setters and Actions
  setUser(user) {
    // initialised from the global-stores blade view
    this.user = user
  },
  setStores(stores) {
    // initialised from the global-stores blade view
    this.stores = stores
  },
  setThreads(threads) {
    // initialised from the header-counter-component blade view
    if (threads) {
      this.threads = [...threads]
    }
  },
  setMessageCounters(counters) {
    // initialised from the header-counter-component blade view
    if (counters) {
      this.accountMessagesTotal = counters.account
      this.storeMessageCounters = counters.stores
    }
  },
  toggleThread(thread) {
    addOrRemoveThread(thread, getParticipantForThread(thread, this.user, this.stores), this)
  },
}
