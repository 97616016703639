import { getAlgoliaResults } from '@algolia/autocomplete-js'

export default (searchClient, indexName) => {
  return {
    sourceId: 'auctions',
    getItemInputValue: ({ item }) => item.query,
    getItems({ query, setContext }) {
      // return empty result if no query is provided
      if (query.length === 0) {
        return []
      }

      return getAlgoliaResults({
        searchClient,
        queries: [
          {
            indexName: indexName,
            query,
            params: {
              hitsPerPage: 4,
              attributesToSnippet: ['title:20', 'seller:20'],
              snippetEllipsisText: '…',
              clickAnalytics: true,
            },
          },
        ],
        transformResponse({ hits, results }) {
          setContext({
            nbProducts: results[0].nbHits,
          })
          return hits
        },
      })
    },
    onSelect({ item }) {
      window.location.href = item.url
    },
    templates: {
      header({ items, createElement, Fragment, state }) {
        if (items.length === 0) {
          return null
        }

        return createElement(
          Fragment,
          null,
          createElement(
            'span',
            { className: 'aa-SourceHeaderTitle' },
            'Product Suggestions for ' + state.query
          ),
          createElement('div', { className: 'aa-SourceHeaderLine' })
        )
      },
      item({ item, components, createElement }) {
        return createElement(
          'a',
          { className: 'aa-ItemLink aa-ProductItem', href: item.url },
          createElement(
            'div',
            { className: 'aa-ItemContent' },
            createElement(
              'div',
              {
                className: 'aa-ItemPicture',
              },
              createElement('img', {
                src: item.image + '?width=480&aspect_ratio=1001:1000',
                alt: item.name,
              })
            ),
            createElement(
              'div',
              { className: 'aa-ItemContentBody' },
              createElement(
                'div',
                {
                  className: 'aa-ItemContentSeller',
                },
                components.Highlight({ hit: item, attribute: 'seller' })
              ),
              createElement(
                'div',
                { className: 'aa-ItemTitleWrapper' },
                createElement(
                  'div',
                  { className: 'aa-ItemContentTitle' },
                  components.Highlight({ hit: item, attribute: 'title' })
                )
              ),
              createElement(
                'div',
                { className: 'aa-ItemContentSubtitle' },
                item.categories[
                  Object.keys(item.categories)[Object.keys(item.categories).length - 1]
                ]
              ),
              createElement('p', { className: 'aa-ItemSku' }, item.sku ? 'SKU: ' + item.sku : null)
            )
          )
        )
      },
      footer({ state, items, createElement, Fragment }) {
        if (items.length === 0 || state.context.nbProducts < 4) {
          return null
        }

        let autocompleteContainer = document.getElementById('autocomplete')
        let url = new URL(autocompleteContainer.dataset.searchUrl)
        url.searchParams.set('query', state.query)

        return createElement(
          Fragment,
          null,
          createElement(
            'a',
            {
              className: 'aa-SeeAllBtn',
              href: url.toString(),
            },
            createElement(
              'span',
              { className: 'aa-SeeAllText' },
              'See all products (' + state.context.nbProducts + ')',
              createElement(
                'svg',
                {
                  className: 'aa-SeeAllIcon',
                  width: 18,
                  height: 18,
                  viewBox: '0 0 24 24',
                  fill: 'none',
                  stroke: 'currentColor',
                  strokeWidth: 2,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                },
                createElement('path', {
                  d: 'M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6',
                }),
                createElement('polyline', { points: '15 3 21 3 21 9' }),
                createElement('line', { x1: 10, y1: 14, x2: 21, y2: 3 })
              )
            )
          )
        )
      },
    },
  }
}
