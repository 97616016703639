import { autocomplete } from '@algolia/autocomplete-js'
import insightsClient from 'search-insights'
import { createAlgoliaInsightsPlugin } from '@algolia/autocomplete-plugin-algolia-insights'
import { recentSearchesPlugin } from './plugins/recentSearchesPlugin.js'
import { querySuggestionsPlugin } from './plugins/querySuggestionsPlugin.js'
import { popularSearchesPlugin } from './plugins/popularSearchesPlugin.js'
import { auctionCategories, auctions, content, help, quickPicks, stores } from './sources'
import { algoliaSearchClient, apiKey, appId } from './utils'

insightsClient('init', { appId, apiKey, useCookie: true })

// set a global userToken by using user id
if (window.userId) {
  insightsClient('setUserToken', 'user-' + window.userId)
}

const algoliaInsightsPlugin = createAlgoliaInsightsPlugin({ insightsClient })

let autocompleteContainer = document.getElementById('autocomplete')

export const setAutocompleteContainer = (container) => {
  autocompleteContainer = container
}

export const autocompleteSearch = () => {
  autocomplete({
    container: '#autocomplete',
    placeholder: 'Search for anything',
    detachedMediaQuery: '',
    openOnFocus: true,
    // debug: true,
    classNames: {
      panel: 'aa-PanelContainer',
    },

    render({ elements, render, html, state }, root) {
      const {
        recentSearchesPlugin,
        querySuggestionsPlugin,
        popularSearchesPlugin,
        auctionCategories,
        auctions,
        stores,
        contents,
        help,
        quickPicks,
      } = elements

      const sourceIdsToExclude = ['recentSearchesPlugin', 'popularSearchesPlugin']
      const hasResults =
        state.collections
          .filter(({ source }) => sourceIdsToExclude.indexOf(source.sourceId) === -1)
          .reduce((prev, curr) => prev + curr.items.length, 0) > 0

      render(
        html` <div class="aa-PanelLayout aa-Panel--scrollable">
          ${!hasResults &&
          state.query &&
          html`
            <div class="aa-NoResultsQuery">No results for <strong>${state.query}</strong></div>
          `}

          <div class="aa-PanelSections">
            <div class="aa-PanelSection--left">
              ${hasResults
                ? (!state.query &&
                    html`
                      ${recentSearchesPlugin &&
                      html`
                        <Fragment>
                          <div class="aa-SourceHeader">
                            <div class="aa-SourceHeaderTitle">Recent searches</div>
                            <div class="aa-SourceHeaderLine"></div>
                          </div>
                          ${recentSearchesPlugin}
                        </Fragment>
                      `}
                      ${popularSearchesPlugin &&
                      html` <div class="aa-PanelSection--popular">${popularSearchesPlugin}</div> `}
                    `) ||
                  (state.query &&
                    html`
                      <Fragment>
                        <div class="aa-SourceHeader">
                          <div class="aa-SourceHeaderTitle">Suggested keywords</div>
                          <div class="aa-SourceHeaderLine"></div>
                        </div>
                        <div class="aa-PanelSectionSources">
                          ${recentSearchesPlugin} ${querySuggestionsPlugin} ${help}
                        </div>
                      </Fragment>
                    `)
                : !hasResults &&
                  state.query &&
                  html`
                    <div class="aa-NoResultsAdvices">
                      <ul class="aa-NoResultsAdvicesList">
                        <li>Double-check your spelling</li>
                        <li>Use fewer keywords</li>
                        <li>Search for a less specific item</li>
                      </ul>
                    </div>
                  `}
            </div>
            <div class="aa-PanelSection--right">
              ${!state.query
                ? html`
                    ${quickPicks &&
                    html`
                      <div class="aa-PanelSection--quickPicks aa-PanelSection--zoomable">
                        ${quickPicks}
                      </div>
                    `}
                  `
                : html` ${auctionCategories &&
                  html`
                    <div class="aa-PanelSection--productCategories">
                      <div class="aa-PanelSectionSource">${auctionCategories}</div>
                    </div>
                  `}
                  ${auctions &&
                  html`
                    <div class="aa-PanelSection--products">
                      <div class="aa-PanelSectionSource">${auctions}</div>
                    </div>
                  `}
                  ${stores &&
                  html`
                    <div class="aa-PanelSection--stores">
                      <div class="aa-PanelSectionSource">${stores}</div>
                    </div>
                  `}
                  ${contents &&
                  html`
                    <div class="aa-PanelSection--articles">
                      <div class="aa-PanelSectionSource">${contents}</div>
                    </div>
                  `}`}
            </div>
          </div>
        </div>`,
        root
      )
    },

    onSubmit(params) {
      window.location.href =
        params.state.query.length > 0
          ? autocompleteContainer.dataset.searchUrl + '?query=' + params.state.query
          : autocompleteContainer.dataset.searchUrl
    },

    getSources({ query }) {
      if (!query) {
        return [quickPicks(algoliaSearchClient, autocompleteContainer.dataset.indexQuickPicks)]
      }
      return [
        auctionCategories(
          algoliaSearchClient,
          autocompleteContainer.dataset.indexAuctionCategories
        ),
        auctions(algoliaSearchClient, autocompleteContainer.dataset.indexAuction),
        stores(algoliaSearchClient, autocompleteContainer.dataset.indexStore),
        content(algoliaSearchClient, autocompleteContainer.dataset.indexContent),
        help(algoliaSearchClient, autocompleteContainer.dataset.indexHelp),
      ]
    },

    plugins: [
      recentSearchesPlugin,
      querySuggestionsPlugin(algoliaSearchClient),
      popularSearchesPlugin(algoliaSearchClient),
      algoliaInsightsPlugin,
    ],
  })
}
