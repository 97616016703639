import { getAlgoliaResults } from '@algolia/autocomplete-js'

export default (searchClient, indexName) => {
  return {
    sourceId: 'help',
    getItemInputValue: ({ item }) => item.query,
    getItems({ query }) {
      // return empty result if no query is provided
      if (query.length === 0) {
        return []
      }

      return getAlgoliaResults({
        searchClient,
        queries: [
          {
            indexName: indexName,
            query,
            params: {
              hitsPerPage: 2,
              attributesToSnippet: ['name:20', 'description:30'],
              snippetEllipsisText: '…',
              clickAnalytics: true,
            },
          },
        ],
      })
    },
    onSelect({ item }) {
      window.location.href = item.url
    },
    templates: {
      item({ item, components, createElement }) {
        return createElement(
          'a',
          { className: 'aa-ItemLink aa-HelpItem', href: item.url },
          createElement(
            'div',
            { className: 'aa-ItemContent' },
            createElement(
              'div',
              { className: 'aa-ItemIcon aa-ItemIcon--noBorder' },
              createElement(
                'svg',
                {
                  viewBox: '0 0 24 24',
                  fill: 'none',
                  stroke: 'currentColor',
                },
                createElement('path', {
                  'stroke-linecap': 'round',
                  'stroke-linejoin': 'round',
                  strokeWidth: '2',
                  d: 'M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z',
                })
              )
            ),
            createElement(
              'div',
              { className: 'aa-ItemContentBody' },
              createElement(
                'div',
                { className: 'aa-ItemContentTitle' },
                components.Highlight({ hit: item, attribute: 'name' })
              )
            )
          ),
          createElement(
            'div',
            { className: 'aa-Breadcrumb' },
            components.Highlight({ hit: item, attribute: 'category' })
          )
        )
      },
    },
  }
}
