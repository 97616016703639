import { getAlgoliaResults } from '@algolia/autocomplete-js'

export default (searchClient, indexName) => {
  return {
    sourceId: 'auctionCategories',
    getItemInputValue: ({ item }) => item.query,
    getItems({ query }) {
      // return empty result if no query is provided
      if (query.length === 0) {
        return []
      }

      return getAlgoliaResults({
        searchClient,
        queries: [
          {
            indexName: indexName,
            query,
            params: {
              hitsPerPage: 4,
              attributesToSnippet: ['name:20'],
              snippetEllipsisText: '…',
              clickAnalytics: true,
            },
          },
        ],
      })
    },
    templates: {
      header({ items, createElement, Fragment, state }) {
        if (items.length === 0) {
          return null
        }

        return createElement(
          Fragment,
          null,
          createElement(
            'span',
            { className: 'aa-SourceHeaderTitle' },
            'Product Categories for ' + state.query
          ),
          createElement('div', { className: 'aa-SourceHeaderLine' })
        )
      },
      item({ item, components, createElement }) {
        return createElement(
          'a',
          { className: 'aa-ItemLink aa-ProductCategoryItem', href: item.url },
          createElement(
            'div',
            { className: 'aa-ItemContent' },
            createElement(
              'div',
              { className: 'aa-ItemPicture' },
              createElement('img', { src: item.image, alt: item.name })
            ),
            createElement(
              'div',
              { className: 'aa-ItemContentBody' },
              createElement(
                'a',
                { className: 'aa-ItemContentTitle', href: item.url },
                createElement(
                  'span',
                  { className: 'aa-ProductCategoryName' },
                  components.Highlight({ hit: item, attribute: 'name' })
                )
              )
            )
          )
        )
      },
    },
  }
}
