import { getAlgoliaResults } from '@algolia/autocomplete-js'

export default (searchClient, indexName) => {
  return {
    sourceId: 'quickPicks',
    getItems({ query }) {
      return getAlgoliaResults({
        searchClient,
        queries: [
          {
            indexName: indexName,
            query,
            params: {
              hitsPerPage: 4,
              attributesToSnippet: ['title:20'],
              snippetEllipsisText: '…',
              clickAnalytics: true,
            },
          },
        ],
      })
    },
    onSelect({ item }) {
      window.location.href = item.url
    },
    templates: {
      header({ createElement, Fragment }) {
        return createElement(
          Fragment,
          null,
          createElement('span', { className: 'aa-SourceHeaderTitle' }, 'Quick Picks'),
          createElement('div', { className: 'aa-SourceHeaderLine' })
        )
      },
      item({ item, createElement }) {
        return createElement(
          'a',
          { className: `${quickpickClasses(item.type)}`, href: item.link },
          createElement(
            'div',
            { className: 'aa-ItemContent' },
            createElement(
              'div',
              { className: 'aa-ItemPicture' },
              createElement('img', {
                src: item.image + '?width=480&aspect_ratio=1001:1000',
                alt: item.title,
              })
            ),
            createElement(
              'div',
              { className: 'aa-ItemContentBody' },
              createElement('div', { className: 'aa-ItemContentTitle' }, item.title),
              (item.type === 'list' &&
                createElement(
                  'ul',
                  { className: '' },
                  item.subtitle.map((string) => {
                    return createElement('li', { className: '' }, string)
                  })
                )) ||
                createElement('div', { className: 'aa-ItemContentSubtitle' }, item.subtitle)
            )
          )
        )
      },
    },
  }
}

function quickpickClasses(type) {
  switch (type) {
    case 'banner':
      return 'aa-ItemLink aa-QuickPicksItem aa-QuickPicksItem--sales-banner'

    case 'list':
      return 'aa-ItemLink aa-QuickPicksItem aa-QuickPicksItem-list'

    default:
      return 'aa-ItemLink aa-QuickPicksItem aa-QuickPicksItem-banner'
  }
}
